/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  var Sage;

  function MkPlayer() {
    this.$element = $('#audio');
    this.$play = false;
    this.$pause = false;
    this.playerElem = false;
    this.exists = function () {
      return this.$element.length;
    };
    this.init = function () {
      this.$play = this.$element.find('.play');
      this.$pause = this.$element.find('.pause');
      this.playerElem = this.$element.find('.player').get(0);
    };
    this.play = function () {
      if (this.exists()) {
        this.$play.hide();
        this.$pause.removeClass("hidden").show();
        this.playerElem.play();
      }
    };
    this.pause = function () {
      if (this.exists()) {
        this.$pause.hide();
        this.$play.removeClass("hidden").show();
        this.playerElem.pause();
      }
    };
    this.registerListeners = function () {
      var self = this;
      this.$play.on('click', function () {
        self.play();
      });
      this.$pause.on('click', function () {
        self.pause();
      });
    };
  }

  function handleSidebarHeight() {
    if($(window).width() > 767) {
      var $sidebar = $('.sidebar').not('.sidebar--sticky');
      var $main = $('.main');

      $sidebar.css("min-height", $main.outerHeight() + "px");
      $main.css("min-heights", $sidebar.outerHeight() + "px");
    }
  }

  /**
   * Loads the Youtube API asynchronously
   */
  function initYoutube() {
    // 1. This code loads the IFrame Player API code asynchronously.
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  function onYoutubeStateChange(event) {
    if (event.data === YT.PlayerState.PLAYING) {
      Sage.mkPlayer.pause();
    }
  }

  function onYouTubeIframeAPIReady() {
    $('.video').each(function () {
      var self = this;
      var player = new YT.Player($(self).attr('id'), {
        videoId: $(self).attr('id'),
        events: {
          'onStateChange': onYoutubeStateChange
        }
      });
    });

    $('.main').imagesLoaded(function () {
      handleSidebarHeight();
    });
  }

  /**
   * Returns the current date in the format YYYY-MM-DD
   * @returns {string} the date
   */
  function printDate() {
    var date = new Date();

    var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    var m = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;

    return date.getFullYear() + "-" + m + "-" + d;
  }

  function handleWeblingForm() {
    // Fill the date fields with the current date and hide them
    var $dateField = $('.webling-form').find('input[type="date"]');
    $dateField.parent().parent().css({display: 'none'});
    $dateField.val(printDate());

    // Remove the option "Ja, gratis"
    var $select = $('.webling-form').find('option[value="Ja, gratis"]').remove();
  }

  function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  Sage = {
    'mkPlayer': new MkPlayer(),
    // All pages
    'common': {
      init: function () {

        // Site Overlay
        if(!getCookie('overlay-hidden')) {
          $('.overlay').addClass('overlay--show');
        }

        $('.overlay__button, .overlay__close').on('click', function() {
          setCookie('overlay-hidden', 'true', 1);
          $('.overlay').removeClass('overlay--show');
        });

        FastClick.attach(document.body);

        if (Sage.mkPlayer.exists()) {
          Sage.mkPlayer.init();
          Sage.mkPlayer.registerListeners();
        }

        var $gallery = $('.gallery-js');
        if ($gallery.length) {
          $gallery.bxSlider({
            mode: 'fade',
            auto: true,
            controls: false,
            pager: false,
            speed: 1000,
            pause: 6000,
            useCss: true,
            adaptiveHeight: true
          });
        }

        $('.main').imagesLoaded(function () {
          handleSidebarHeight();
        });


        handleWeblingForm();

        $('form').on('submit', function (event) {
          var requiredFields = $(this).find("[required]");

          $(requiredFields).each(function () {
            if ($(this).val() === '') {
              alert("Bitte füllen Sie die zwingenden Felder (*) aus.");

              $(this).focus();

              event.preventDefault();
              return false;
            }
          });
          return true;
        });

        var header = $('.navigation');

        $(window).scroll(function() {
          if ($(window).scrollTop() > 0) {
            if(!header.hasClass('navigation--scrolled')) {
              header.addClass('navigation--scrolled');
            }
          } else {
            if(header.hasClass('navigation--scrolled')) {
              header.removeClass('navigation--scrolled');
            }
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    'single_event': {
      init: function () {
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
        initYoutube();

        $('.single-event__tickets').on('click', function(event) {

          var id = $(this).data('id');

          $([document.documentElement, document.body]).animate({
            scrollTop: $( '#' + id ).offset().top - 200
          }, 1000);

        });
      }
    },
    'single_mc_world': {
      init: function () {
        $('.mc-world-archive__link-js').on('click', function (event) {
          event.preventDefault();
          var $links = $(this).next('.mc-world-archive__links-js');
          if ($links.is(":visible")) {
            $links.slideUp(100, handleSidebarHeight);
          } else {
            $links.slideDown(100, handleSidebarHeight);
          }
        });
      }
    },
    'page_template_mc_world': {
      init: function () {
        Sage.single_mc_world.init();
      }
    },
    'page_template_backgrounds': {
      init: function () {
        var contents = [$('.banner-top'), $('.banner-bottom'), $('.wrap.container')];
        $("a#show-content").on('click', function (event) {
          event.preventDefault();
          if ($(this).attr('href') === '#content-on') {
            $(this).attr('href', '#content-off');
            $(this).html('Inhaltsblöcke ausblenden');
            contents.forEach(function ($entry) {
              $entry.show();
            });
          } else {
            $(this).attr('href', '#content-on');
            $(this).html('Inhaltsblöcke anzeigen');
            contents.forEach(function ($entry) {
              $entry.hide();
            });
          }
        });
        var $av_bgs = $("#av-bgs");
        $("#av-bgs-link").on('click', function (event) {
          event.preventDefault();
          if ($av_bgs.is(':visible')) {
            $av_bgs.hide();
          } else {
            $av_bgs.attr('style', 'display: inline-block;');
          }
        });

        var $currentBg = $('#current-background span');
        $("#av-bgs a").on('click', function (event) {
          event.preventDefault();
          var url = $(this).attr('href');
          var text = $(this).html();
          $currentBg.html(text);
          $('body').attr('style', 'background-image: url(' + url + ');');
        });
      }
    },
    'page_template_gallery': {
      fadeOutAudio: function (audio) {
        setTimeout(function () {
          if (audio.volume > 0 && (audio.volume - 0.1) >= 0) {
            audio.volume = audio.volume - 0.2;
            Sage.page_template_gallery.fadeOutAudio(audio);
          } else if (audio.volume > 0) {
            audio.volume = 0;
            Sage.page_template_gallery.fadeOutAudio(audio);
          } else {
            audio.pause();
            audio.volume = 1;
          }
        }, 500);
      },
      galleryOpen: function ($element) {
        Sage.mkPlayer.pause();
        var $galleryMusic = $element.parents('.gallery').find('audio');
        if ($galleryMusic.length) {
          $galleryMusic.get(0).play();
        }
      },
      galleryClose: function ($element) {
        var $galleryMusic = $element.parents('.gallery').find('audio');
        if ($galleryMusic.length) {
          var audio = $galleryMusic.get(0);
          audio.pause();
          audio.currentTime = 0;
        }
      },
      galleryPlayEnd: function ($element) {
        var $galleryMusic = $element.parents('.gallery').find('audio');
        if ($galleryMusic.length) {
          var audio = $galleryMusic.get(0);
          Sage.page_template_gallery.fadeOutAudio(audio);
        }
      },
      init: function () {
        $('.fancybox').fancybox({
          padding: 0,
          autoPlay: true,
          nextEffect: 'fade',
          prevEffect: 'fade',
          arrows: false,
          loop: false,
          beforeShow: function () {
            Sage.page_template_gallery.galleryOpen(this.element);
          },
          afterClose: function () {
            Sage.page_template_gallery.galleryClose(this.element);
          },
          onCancel: function () {
            Sage.page_template_gallery.galleryClose(this.element);
          },
          onPlayEnd: function (oEvent) {
            Sage.page_template_gallery.galleryPlayEnd(this.element);
          }
        });
        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
        initYoutube();
      }
    },
    'page_template_program': {
      init: function () {
        $('.widget-gallery-js').bxSlider({
          controls: false,
          pager: false,
          randomStart: true,
          auto: true,
          preloadImages: true
        });
      },
      finalize: function () {
      }
    },
    'page_template_splitscreen': {
      $flecken: $(".flecken"),
      shiftFlecken: function (degX, degY) {
        this.$flecken.each(function () {
          if ($(this).hasClass('flecken--blue')) {
            $(this).css({transform: "rotateX(" + degX + "deg) rotateY(" + degY + "deg) rotateZ(-15deg)"});
          }
          else {
            $(this).css({transform: "rotateX(" + degX + "deg) rotateY(" + degY + "deg)"});
          }
        });
      },
      init: function () {
        var $tiltshift = $(".tiltshift-js");

        $tiltshift.on('mousemove', function (event) {
          var degX = (event.pageX / $tiltshift.width() - 0.5) * 15;
          var degY = (event.pageY / $tiltshift.height() - 0.5) * 15;
          Sage.page_template_splitscreen.shiftFlecken(degX, degY);
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
